import axios from "axios";
import $ from "jquery";
import lodash from "lodash";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import { convertDateToJavaFriendlyFormat } from "../../../util/dates";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import FieldAddress from "../../common/FieldAddress";
import FieldBirthDate from "../../common/FieldBirthDate";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldCompanyType from "../../common/FieldCompanyType";
import FieldDate from "../../common/FieldDate";
import FieldPhone from "../../common/FieldPhone";
import FieldSegmentCode from "../../common/FieldSegmentCode";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import FieldTextarea from "../../common/FieldTextarea";
import FieldTimezone from "../../common/FieldTimezone";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import FieldSelectLanguage from "../../common/FieldSelectLanguage";

class Company extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            company: {},

            creditMerchantAccountList: {},
            walletMerchantAccountList: {},
            emailReminderSetting: {},

            tenantUsesCreditReport: false,

            manageBalanceBool: true,
            overpaymentAllowedBool:false,
            onlyPMPaymentAllowedBool:false,
            propertyManagementEmailBool: true,
            pmPadAdvanceReminderEmailBool: false,

            admins: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            paymentProcessor: null,

            notificationProvider: null,

            validationList: [],
        };

        this.MAX_NSF_DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        this.MAX_AUTO_FUNDING_CUTOFF_DAYS =
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
             11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
             21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
             31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
             41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
             51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
             61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
             71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
             81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
             91, 92, 93, 94, 95, 96, 97, 98, 99];

        this.getAdmins = this.getAdmins.bind(this);

        this.handleChangeOverpayment = this.handleChangeOverpayment.bind(this);
        this.handleChangePmManageCharge = this.handleChangePmManageCharge.bind(this);
        this.searchAllTenants = this.searchAllTenants.bind(this);
        
        this.handleChangeCreditMerchantAccount = this.handleChangeCreditMerchantAccount.bind(this);
        this.handleChangeUnionPayMerchantAccount = this.handleChangeUnionPayMerchantAccount.bind(this);
        this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleChangeOverpayment = this.handleChangeOverpayment.bind(this);
        this.handleChangePmManageCharge = this.handleChangePmManageCharge.bind(this);
        this.handleChangeOverpaymentModal = this.handleChangeOverpaymentModal.bind(this);
        this.handleChangeOnlyPMPayment = this.handleChangeOnlyPMPayment.bind(this);
        this.handlePmManageChargeModal = this.handlePmManageChargeModal.bind(this);
        this.handleChangePropertyManagementEmail = this.handleChangePropertyManagementEmail.bind(this);

        this.getMerchantAccountsAll = this.getMerchantAccountsAll.bind(this);

        this.filterMerchantAccountsByProcessor = this.filterMerchantAccountsByProcessor.bind(this);
        this.switchToHistoricalMerchantAccounts = this.switchToHistoricalMerchantAccounts.bind(this);
        this.handleChangeProcessor = this.handleChangeProcessor.bind(this);
        this.handleChangeNotificationProvider = this.handleChangeNotificationProvider.bind(this);
        this.handleChangeApplePayMerchantAccount = this.handleChangeApplePayMerchantAccount.bind(this);
        this.handleChangeGooglePayMerchantAccount = this.handleChangeGooglePayMerchantAccount.bind(this);
        this.handleChangePMPadAdvanceReminderEmail = this.handleChangePMPadAdvanceReminderEmail.bind(this);
    }

    /**
     * Load the required data on the mounting of the component. Fetch the company based on the ID provided in the route.
     * Fetch the parent company if the company has a parent ID. Fetch a list of admin accounts for populating account
     * and sales manager select boxes. Fetch a list of merchant accounts for populating default merchant account select
     * boxes.
     */
    componentDidMount() {
        this.getCompanyDetails();
    }

    /**
     * Handle changes in the route's ID.
     *
     * @param prevProps - The previous props.
     * @param prevState - The previous state.
     * @param snapshot - A snapshot of the data.
     */
    componentDidUpdate(prevProps, prevState, snapshot) {

        const oldId = prevProps.match.params.companyId;
        const newId = this.props.match.params.companyId;

        if(oldId !== newId) {
            if(this.state.parentCompany?.id) {
                // Get the latest merchant accounts always
                this.getMerchantAccountsAll(this.state.parentCompany?.id, this.state.paymentProcessor, this.state.parentCompany?.id);
            }

            let pmPadAdvanceReminderEmailBoolean = this.state?.parentEmailReminderSetting?.pmPadAdvanceReminderEmail === 'YES';
            this.setState(prevState => ({
                admins: prevState.admins,
                creditMerchantAccountList: prevState.parentCreditMerchantAccountList,
                walletMerchantAccountList: prevState.parentWalletMerchantAccountList,
                emailReminderSetting: prevState.parentEmailReminderSetting,
                parentCreditMerchantAccountList:{},
                parentWalletMerchantAccountList:{},
                parentEmailReminderSetting:{},
                // bankMerchantAccounts: prevState.bankMerchantAccounts,
                // paypalMerchantAccounts: prevState.paypalMerchantAccounts,
                // cashMerchantAccounts: prevState.cashMerchantAccounts,
                // padMerchantAccounts: prevState.padMerchantAccounts,
                // visaMerchantAccounts: prevState.visaMerchantAccounts,
                // visaElectronMerchantAccounts: prevState.visaElectronMerchantAccounts,
                // mastercardMerchantAccounts: prevState.mastercardMerchantAccounts,
                // maestroMerchantAccounts: prevState.maestroMerchantAccounts,
                // americanExpressMerchantAccounts: prevState.americanExpressMerchantAccounts,
                // discoverMerchantAccounts: prevState.discoverMerchantAccounts,
                // dinersClubMerchantAccounts: prevState.dinersClubMerchantAccounts,
                // jcbMerchantAccounts: prevState.jcbMerchantAccounts,
                // unionPayMerchantAccounts: prevState.unionPayMerchantAccounts,
                creditMerchantAccounts: prevState.creditMerchantAccounts,
                company: prevState.parentCompany,
                parentCompany: {},
                manageBalanceBool: prevState.parentManageBalanceBool,
                parentManageBalanceBool:{},
                overpaymentAllowedBool: prevState.parentOverpaymentAllowedBool,
                onlyPMPaymentAllowedBool: prevState.parentOnlyPMPaymentAllowedBool,
                propertyManagementEmailBool: prevState.parentpropertyManagementEmailBool,
                pmPadAdvanceReminderEmailBool: pmPadAdvanceReminderEmailBoolean,
                parentOverpaymentAllowedBool:{},
                parentOnlyPMPaymentAllowedBool:{}
            }));
        }
    }

    /**
     * Get the details of the company
     */
    getCompanyDetails() {
        
        this.setState(() => ({
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(responseCompany => {

            let manageBalanceBoolean = responseCompany.data.manageBalance === 'YES';
            let overpaymentAllowedBoolean = responseCompany.data.overpaymentAllowed === 'YES';
            let onlyPMPaymentAllowedBoolean = responseCompany.data?.onlyPMPaymentAllowed === 'YES';
            let propertyManagementEmailBoolean = responseCompany.data.propertyManagementEmail === 'YES';
            let pmPadAdvanceReminderEmailBoolean = responseCompany.data?.emailReminderSetting?.pmPadAdvanceReminderEmail === 'YES';

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                company: responseCompany.data,
                paymentProcessor: responseCompany.data?.paymentProviderId,
                manageBalanceBool: manageBalanceBoolean,
                overpaymentAllowedBool: overpaymentAllowedBoolean,
                onlyPMPaymentAllowedBool: onlyPMPaymentAllowedBoolean,
                propertyManagementEmailBool: propertyManagementEmailBoolean,
                pmPadAdvanceReminderEmailBool: pmPadAdvanceReminderEmailBoolean,
                creditMerchantAccountList: responseCompany.data.creditMerchantAccountList,
                walletMerchantAccountList: responseCompany.data.walletMerchantAccountList,
                emailReminderSetting: responseCompany.data?.emailReminderSetting
            }));

            this.getAdmins();

            let companyId = this.props.match.params.companyId;

            this.getMerchantAccountsAll(companyId, responseCompany.data?.paymentProviderId, responseCompany.data?.parentId);

            if(responseCompany.data.parentId) {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${responseCompany.data.parentId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    const manageBalanceBool = response.data.manageBalance === 'YES';
                    const overpaymentAllowedBool = response.data.overpaymentAllowed === 'YES';
                    const onlyPMPaymentAllowedBool = response.data?.onlyPMPaymentAllowed === 'YES';
                    const propertyManagementEmailBool = response.data.propertyManagementEmail === 'YES';

                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false,
                        parentCompany: response.data,
                        parentManageBalanceBool: manageBalanceBool,
                        parentOverpaymentAllowedBool: overpaymentAllowedBool,
                        parentOnlyPMPaymentAllowedBool: onlyPMPaymentAllowedBool,
                        parentpropertyManagementEmailBool: propertyManagementEmailBool,
                        parentCreditMerchantAccountList: response.data.creditMerchantAccountList,
                        parentWalletMerchantAccountList: response.data.walletMerchantAccountList,
                        parentEmailReminderSetting: response.data?.emailReminderSetting
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });

        this.searchAllTenants(this.props.match.params.companyId)
    }

    /**
     * Get data linked with different kind of merchant accounts 
     * @param companyId - the current company id 
     */
    getMerchantAccountsAll(companyId, paymentProcessor=null, parentCompanyId=null) {
        this.getAllMerchantAccountsPromise(companyId).then(response => {
        if(!response.data?.records) {
            return;
        }
        const visaMerchantAccounts = [];
        const visaElectronMerchantAccounts = [];
        const mastercardMerchantAccounts = [];
        const maestroMerchantAccounts = [];
        const americanExpressMerchantAccounts = [];
        const discoverMerchantAccounts = [];
        const dinersClubMerchantAccounts = [];
        const jcbMerchantAccounts = [];
        const unionPayMerchantAccounts = [];
        const bankMerchantAccounts = [];
        const paypalMerchantAccounts = [];
        const cashMerchantAccounts = [];
        const padMerchantAccounts = [];
        const applePayMerchantAccounts = [];
        const googlePayMerchantAccounts = [];

        response.data.records.forEach((i) => {
            switch(i.paymentType) {
                case 'TYPE_CREDIT_CARD':
                    if(!i.joins?.pp?.supportedCards) break;
                    if(i.joins.pp.supportedCards.includes('VISA'))
                        visaMerchantAccounts.push(i);
                    if(i.joins.pp.supportedCards.includes('VISA_ELECTRON'))
                        visaElectronMerchantAccounts.push(i);
                    if(i.joins.pp.supportedCards.includes('MASTERCARD'))
                        mastercardMerchantAccounts.push(i);
                    if(i.joins.pp.supportedCards.includes('MAESTRO'))
                        maestroMerchantAccounts.push(i);
                    if(i.joins.pp.supportedCards.includes('AMERICAN_EXPRESS'))
                        americanExpressMerchantAccounts.push(i);
                    if(i.joins.pp.supportedCards.includes('DISCOVER'))
                        discoverMerchantAccounts.push(i);
                    if(i.joins.pp.supportedCards.includes('DINERS_CLUB'))
                        dinersClubMerchantAccounts.push(i);
                    if(i.joins.pp.supportedCards.includes('JCB'))
                        jcbMerchantAccounts.push(i);
                    if(i.joins.pp.supportedCards.includes('UNION_PAY'))
                        unionPayMerchantAccounts.push(i);
                    break;
                case 'TYPE_BANK_ACCOUNT':
                    bankMerchantAccounts.push(i);
                    padMerchantAccounts.push(i);
                    break;
                case 'TYPE_PAY_PAL':
                    paypalMerchantAccounts.push(i);
                    break;
                case 'TYPE_CASH':
                    cashMerchantAccounts.push(i);
                    break;
                case 'TYPE_WALLET':
                    if(!i.joins?.pp?.supportedWallets) break;
                    if(i.joins.pp.supportedWallets.includes('APPLE_PAY'))
                        applePayMerchantAccounts.push(i);
                    if(i.joins.pp.supportedWallets.includes('GOOGLE_PAY'))
                        googlePayMerchantAccounts.push(i);
                    break;
                default:
                    break;
            }
        })
        this.setState(prevState => ({
            ...prevState,
            visaMerchantAccounts,
            visaMerchantAccountsAll: visaMerchantAccounts,
            visaElectronMerchantAccounts,
            mastercardMerchantAccounts,
            maestroMerchantAccounts,
            americanExpressMerchantAccounts,
            discoverMerchantAccounts,
            dinersClubMerchantAccounts,
            jcbMerchantAccounts,
            unionPayMerchantAccounts,
            bankMerchantAccounts,
            bankMerchantAccountsAll: bankMerchantAccounts,
            paypalMerchantAccounts,
            cashMerchantAccounts,
            padMerchantAccounts,
            padMerchantAccountsAll: padMerchantAccounts,
            applePayMerchantAccounts,
            applePayMerchantAccountsAll: applePayMerchantAccounts,
            googlePayMerchantAccounts,
            googlePayMerchantAccountsAll: googlePayMerchantAccounts
        }));
        if(paymentProcessor) {
            this.filterMerchantAccountsByProcessor(paymentProcessor);
        }
    }).catch(error => {
        this.handleValidation(error);
    });
}

    /**
     * Searches all tenants, including sub-companies, to determine if credit reporting is used
     * If a tenant has credit reporting, grey out and set manageBalance to true/checked
     * @param {*} companyId 
     */
    searchAllTenants(companyId) {
        let propertyLeaseQuery = {
            orderBy: 'ASC',
            orderByFields: ['id'],
            conditionList: [{
                    type: 'STRING',
                    logicalOperator: 'AND',
                    operator: 'EQUALS',
                    fieldName: 'companyId',
                    fieldValue: companyId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    operator: 'EQUALS',
                    fieldName: 'cr.status',
                    fieldValue: 'ACTIVE'  
                }
            ],
            joins: {
                cr: {
                    targetRecordType: 'TYPE_CREDIT_REPORT',
                    joinField: 'id',
                    targetField: 'billingAccountId',
                    alias: 'cr',
                    returnFields: ['status'],
                },
                comp: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    targetField: 'id',
                    alias: 'comp',
                    returnFields: ['id', 'name', 'parentId'],
                }
            },
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, propertyLeaseQuery, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let propertyLeases = response.data;
            let tentantHasCreditReport = false;

            propertyLeases.records.forEach((propertyLease) => {
                if(propertyLease?.joins?.cr?.status === 'ACTIVE' && !tentantHasCreditReport){
                    this.setState(prevState => ({
                        ...prevState,
                        tenantUsesCreditReport: true,
                        manageBalanceBool: true
                    }));
                    tentantHasCreditReport = true;
                }
            });

            /*if (this.state.company.manageBalance === "NO" && tentantHasCreditReport) {
                axios.patch(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.company.id}/update`, {
                    manageBalance: 'YES'
                }, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false,
                        company: response.data,
                        validationList: [{
                            fields: {},
                            alert: {
                                type: 'primary',
                                code: 'admin.companies.pmManageBalance.updated'
                            }
                        }],
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });
            }*/

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Fetch a list of admin accounts for populating the account and sales managers select boxes.
     */
    getAdmins() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/admin/search`, {
            orderBy: 'ASC',
            orderByFields: ['firstName', 'lastName']
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                admins: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the default credit/debit merchant account field. By selecting a merchant account from this
     * field, the same merchant account will be applies to all credit/debit card brands available.
     *
     * @param event - The event container.
     */
    handleChangeCreditMerchantAccount(event) {

        event.preventDefault();

        let creditMerchantAccountList = this.state.creditMerchantAccountList;
        let creditMerchantAccountId = event.target.value;

        creditMerchantAccountList['VISA'] = creditMerchantAccountId;
        creditMerchantAccountList['VISA_ELECTRON'] = creditMerchantAccountId;
        creditMerchantAccountList['MASTERCARD'] = creditMerchantAccountId;
        creditMerchantAccountList['MAESTRO'] = creditMerchantAccountId;
        creditMerchantAccountList['AMERICAN_EXPRESS'] = creditMerchantAccountId;
        creditMerchantAccountList['DISCOVER'] = creditMerchantAccountId;
        creditMerchantAccountList['DINERS_CLUB'] = creditMerchantAccountId;
        creditMerchantAccountList['JCB'] = creditMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            creditMerchantAccountList: creditMerchantAccountList,
        }));
    }

    /**
     * Handle changes to the default Apple Pay merchant account field. By selecting a merchant account from this field.
     *
     * @param event - The event container.
     */
    handleChangeApplePayMerchantAccount(event) {

        event.preventDefault();

        let walletMerchantAccountList = this.state.walletMerchantAccountList;
        let applePayMerchantAccountId = event.target.value;

        walletMerchantAccountList['APPLE_PAY'] = applePayMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            walletMerchantAccountList: walletMerchantAccountList,
        }));
    }

    /**
     * Handle changes to the default Google Pay merchant account field. By selecting a merchant account from this field.
     *
     * @param event - The event container.
     */
    handleChangeGooglePayMerchantAccount(event) {

        event.preventDefault();

        let walletMerchantAccountList = this.state.walletMerchantAccountList;
        let googlePayMerchantAccountId = event.target.value;

        walletMerchantAccountList['GOOGLE_PAY'] = googlePayMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            walletMerchantAccountList: walletMerchantAccountList,
        }));
    }

    /**
     * Handles change to the union pay merchant account
     * @param {*} event 
     */
    handleChangeUnionPayMerchantAccount(event) {
        
        event.preventDefault();
        event.persist();

        this.setState(prevState => ({
            ...prevState,
            company: {
                ...prevState.company,
                creditMerchantAccountList : {
                    ...prevState.company.creditMerchantAccountList,
                    [event.target?.name] : event.target?.value
                }
            }
        }));
    }

    /**
     * Handle updating the company's status.
     *
     * @param status - The new status of the company.
     */
    handleUpdateStatus(status) {
        let company = this.state.company;

        company['status'] = status;

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, company, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.company.status.' + status
                    }
                }],
            })
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting updates to the company.
     * 
     * @param event - The event container.
     */
    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            spinner: true,
        });

        let company = lodash.cloneDeep(this.state.company);

        company.manageBalance = this.state.manageBalanceBool ? 'YES' : 'NO';
        company.overpaymentAllowed = this.state.overpaymentAllowedBool ? 'YES' : 'NO';
        company.onlyPMPaymentAllowed = this.state.onlyPMPaymentAllowedBool ? 'YES' : 'NO';
        company.propertyManagementEmail = this.state.propertyManagementEmailBool ? 'YES' : 'NO';
        company.creditMerchantAccountList = this.state.creditMerchantAccountList;
        company.creditMerchantAccountList['UNION_PAY'] = lodash.cloneDeep(this.state.company.creditMerchantAccountList?.['UNION_PAY']);
        company.masterAgreementDate = this.state.company?.masterAgreementDate ? convertDateToJavaFriendlyFormat(this.state.company.masterAgreementDate) : undefined;
        company.walletMerchantAccountList = this.state.walletMerchantAccountList;
        company.emailReminderSetting = this.state.emailReminderSetting;

        Object.entries(company.creditMerchantAccountList).forEach(([key, value]) => {
            if(value === '') {
                delete company.creditMerchantAccountList[key];
            }
        });

        Object.entries(company.walletMerchantAccountList).forEach(([key, value]) => {
            if(value === '') {
                delete company.walletMerchantAccountList[key];
            }
        });

        Object.entries(company.emailReminderSetting).forEach(([key, value]) => {
            if(value === '') {
                delete company.emailReminderSetting[key];
            }
        });

        const paymentProcessor = this.state.paymentProcessor;
        company.paymentProviderId = paymentProcessor;
        company.rmProviderSummary.providerMap[paymentProcessor] = {
            creditMerchantAccountList:  lodash.cloneDeep(company.creditMerchantAccountList),
            bankMerchantAccountId:  lodash.cloneDeep(company.bankMerchantAccountId),
            padMerchantAccountId:  lodash.cloneDeep(company.padMerchantAccountId),
            splitItMerchantAccountId: lodash.cloneDeep(company.splitItMerchantAccountId),
            walletMerchantAccountList:  lodash.cloneDeep(company.walletMerchantAccountList),
        }

        if(!company.maximumPaymentAmount || company.maximumPaymentAmount === ''){

            company.maximumPaymentAmount = '30000';
        }

        if(!company.softMaximumPaymentAmount || company.softMaximumPaymentAmount === ''){

            company.softMaximumPaymentAmount = '5000';
        }

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, company, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved',
                        message: 'Changes have been saved'
                    }
                }],
            });

            this.getCompanyDetails();
        }).catch(error => {
            this.handleValidation(error);
        });

        // If PM DOES NOT manage balance, set 'blockpartialpayments' to false for each tenant in the company
        if(!this.state.manageBalanceBool){
            axios.patch(`${constants.REACT_APP_HOST_API_URL}/company/partialpayments`, {
                blockPayments: false,
                companyId: this.props.match.params.companyId
            }, {
                headers: this.generateRequestHeaders()
            }).then(response =>{
                
            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Show confirm modal for manage charge setting
     * 
     * @param event - The event container.
     */
    handleChangePmManageCharge(event){
        event.persist();
        $("#manage-charge-confirm").modal("show");
    }

    /**
     * Save the PM manage charge setting from the confirm modal
     */
    handlePmManageChargeModal(){
        // Set 'Allow Overpayment' to true if the PM does not manage charges; checkbox is grayed out by UI
        // Note: Default value for 'Allow Overpayment' is false
        this.setState(prevState =>({
            ...prevState,
            manageBalanceBool: !prevState.manageBalanceBool,
            overpaymentAllowedBool: prevState.manageBalanceBool ? true : false,
            company : {
                ...prevState.company,
                displayChargeCodes: prevState.manageBalanceBool ? prevState.company?.displayChargeCodes : false,
                enforceCustomAmount : !prevState.manageBalanceBool ? prevState.company?.enforceCustomAmount : false
            }
        }));
    }

    /**
     * Save Property Management Email
     * 
     */
    handleChangePropertyManagementEmail(){
        this.setState(prevState =>({
            ...prevState,
            propertyManagementEmailBool: !prevState.propertyManagementEmailBool
        }));
    }

    /**
     * Save PM PAD Advance Reminder Email
     *
     */
    handleChangePMPadAdvanceReminderEmail(){

        let pmPadAdvanceReminderEmailBoolean = !this.state.pmPadAdvanceReminderEmailBool;

        this.setState(prevState =>({
            ...prevState,
            pmPadAdvanceReminderEmailBool: pmPadAdvanceReminderEmailBoolean
        }));


        if(pmPadAdvanceReminderEmailBoolean){

            this.setState(prevState => ({
                ...prevState,
                emailReminderSetting: {
                    ...prevState.emailReminderSetting,
                    pmPadAdvanceReminderEmail : 'YES'
                }
            }));

        } else {

            this.setState(prevState => ({
                ...prevState,
                emailReminderSetting: {
                    ...prevState.emailReminderSetting,
                    pmPadAdvanceReminderEmail : 'NO'
                }
            }));
        }
    }

    /**
     * Show confirm page for overpayment setting
     * 
     * @param event - The event container.
     */
    handleChangeOverpayment(event){
        event.persist();
        $("#allow-overpayment-confirm").modal("show");
    }

    /**
     * Save the overpayment allowed setting from the confirm modal
     */
    handleChangeOverpaymentModal(){
        this.setState(prevState =>({
            ...prevState,
            overpaymentAllowedBool: !prevState.overpaymentAllowedBool
        }));
    }

    /**
     * Save the onlyPMPayment allowed setting
     */
    handleChangeOnlyPMPayment(event){
        event.persist();
        this.setState(prevState =>({
            ...prevState,
            onlyPMPaymentAllowedBool: !prevState.onlyPMPaymentAllowedBool
        }));
    }

    /**
     * Filter merchant accounts by processor type
     * This function only Impact Credit/Debit, Bank, and PAD Merchant accounts
     * @param {*} processor 
     */
    filterMerchantAccountsByProcessor(paymentProcessor) {
        const padMerchantAccounts = this.state.padMerchantAccountsAll.filter(
                (padMerchantAccount) =>  padMerchantAccount.paymentProviderId === paymentProcessor
            );
        const bankMerchantAccounts = this.state.bankMerchantAccountsAll.filter(
                (bankMerchantAccount) => bankMerchantAccount.paymentProviderId === paymentProcessor
            );
        const visaMerchantAccounts = this.state.visaMerchantAccountsAll.filter(
                (visaMerchantAccount) => visaMerchantAccount.paymentProviderId === paymentProcessor
            );
        const applePayMerchantAccounts = this.state.applePayMerchantAccountsAll.filter(
            (applePayMerchantAccount) => applePayMerchantAccount.paymentProviderId === paymentProcessor
        );
        const googlePayMerchantAccounts = this.state.googlePayMerchantAccountsAll.filter(
            (googlePayMerchantAccount) => googlePayMerchantAccount.paymentProviderId === paymentProcessor
        );
        
        this.setState(prevState => ({
            ...prevState,
            padMerchantAccounts: padMerchantAccounts,
            bankMerchantAccounts: bankMerchantAccounts,
            visaMerchantAccounts: visaMerchantAccounts,
            applePayMerchantAccounts: applePayMerchantAccounts,
            googlePayMerchantAccounts: googlePayMerchantAccounts
        }));
    }

    /**
     * Switch back to the appropriate historical data once the MA processor is changed.
     */
    switchToHistoricalMerchantAccounts(paymentProcessor) {
        this.setState(prevState => ({
            ...prevState,
            company: {
                ...prevState.company,
                bankMerchantAccountId: lodash.cloneDeep(this.state.company?.rmProviderSummary?.providerMap?.[paymentProcessor]?.bankMerchantAccountId) || null,
                creditMerchantAccountList: lodash.cloneDeep(this.state.company?.rmProviderSummary?.providerMap?.[paymentProcessor]?.creditMerchantAccountList) || {},
                padMerchantAccountId: lodash.cloneDeep(this.state.company?.rmProviderSummary?.providerMap?.[paymentProcessor]?.padMerchantAccountId) || null,
                splitItMerchantAccountId: lodash.cloneDeep(this.state.company?.rmProviderSummary?.providerMap?.[paymentProcessor]?.splitItMerchantAccountId) || null,
                walletMerchantAccountList: lodash.cloneDeep(this.state.company?.rmProviderSummary?.providerMap?.[paymentProcessor]?.walletMerchantAccountList) || {},
            },
            bankMerchantAccountId: lodash.cloneDeep(this.state.company?.rmProviderSummary?.providerMap?.[paymentProcessor]?.bankMerchantAccountId) || null,
            creditMerchantAccountList: lodash.cloneDeep(this.state.company?.rmProviderSummary?.providerMap?.[paymentProcessor]?.creditMerchantAccountList) || {},
            padMerchantAccountId: lodash.cloneDeep(this.state.company?.rmProviderSummary?.providerMap?.[paymentProcessor]?.padMerchantAccountId) || null,
            walletMerchantAccountList: lodash.cloneDeep(this.state.company?.rmProviderSummary?.providerMap?.[paymentProcessor]?.walletMerchantAccountList) || {},
        }));
    }

    /**
     * Handles the change in processor
     */
    handleChangeProcessor(event) {
        const { value } = event.target;
        this.filterMerchantAccountsByProcessor(value);
        this.switchToHistoricalMerchantAccounts(value);
        this.setState(prevState => ({
            ...prevState,
            paymentProcessor: value
        }));
    }

    handleChangeNotificationProvider(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            company:{...prevState.company, notificationProvider: event.target.value}
        }));

    }
    
    /**
     * Render the component.
     *
     * @returns {*} - The edit child company component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <Modal  id="manage-charge-confirm" theme="danger" iconType="fas" iconName="exclamation-triangle" 
                        body="Are you sure you want to change PM charge management setting for this company?" title="PM Manage Charges">
                        
                        <small className="form-text pb-2">In order for these changes to take effect, please hit 'Save' button below</small>
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => $("#manage-charge-confirm").modal("hide")}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => this.handlePmManageChargeModal()} className="btn btn-danger btn-lg" data-dismiss="modal">
                            {this.state.manageBalanceBool ? 'Disallow': 'Allow'} PM Manage Charges
                        </button>
                </Modal>

                <Modal id="allow-overpayment-confirm" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Allow Overpayment"
                           body="Are you sure you want to change the overpayment setting for this company?">

                        <small className="form-text pb-2">In order for these changes to take effect, please hit 'Save' button below</small>
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => $("#allow-overpayment-confirm").modal("hide")}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => this.handleChangeOverpaymentModal()} className="btn btn-danger btn-lg" data-dismiss="modal">
                            {this.state.overpaymentAllowedBool ? 'Disallow': 'Allow'} Overpayment
                        </button>
                </Modal>

                <form onSubmit={this.handleSubmit}>
                    
                    {this.state.company &&
                    <div className="card">
                        <div className="card-header">
                            Company Details
                        </div>
                        <div className="card-body">

                            <FieldText id="name" label="Company Name" model="company" parent={this} value={this.state.company['name']}/>

                            <FieldText id="legalName" label="Legal Name" model="company" parent={this} value={this.state.company['legalName']}/>

                            <FieldCompanyType id="companyType" label="Company Type" model="company" parent={this} value={this.state.company['companyType']}/>

                            <FieldText id="companyIdentifier" label="Company Identifier" model="company" parent={this} value={this.state.company['companyIdentifier']}/>

                            <FieldSegmentCode id="segmentCode" label="Segment Code" model="company" parent={this} required={true} value={this.state.company['segmentCode']}/>

                            <FieldPhone id="phone" label="Business Phone" model="company" parent={this} value={this.state.company['phone']}/>

                            <FieldText id="taxNumber" label="Tax ID" model="company" parent={this} value={this.state.company['taxNumber']}/>

                            <FieldTimezone id="timeZone" label="Timezone" model="company" parent={this} value={this.state.company['timeZone']}/>

                            <FieldBirthDate id="inceptionDate" label="Inception Date" model="company" parent={this} required={true} value={this.state.company['inceptionDate']} monthFirst={true}/>

                            <FieldSelectLanguage id="languageCode" label="Tenant Language Default" model="company" parent={this} value={this.state.company['languageCode']}/>

                            <FieldCheckbox id="propertyManagementEmail" label="Property Management emails" fieldLabel="Use Property Management emails"
                                           handleChange={this.handleChangePropertyManagementEmail}
                                           help="Emails sent to tenants are customized for Property Managers."
                                           parent={this} value={this.state.propertyManagementEmailBool} />

                            <FieldCheckbox id="pmPadAdvanceReminderEmail" label="Reminder email" fieldLabel="Advanced Reminder Email for PAD"
                                           handleChange={this.handleChangePMPadAdvanceReminderEmail}
                                           help="If checked a reminder email sent 2 days in advanced to Property Managers in this company."
                                           parent={this} value={this.state.pmPadAdvanceReminderEmailBool} />

                            <FieldCheckbox id="allowOverpayment" label="Overpayment" fieldLabel="Allow overpayment" 
                                           data-toggle="modal" data-target="#manage-charge-confirm"
                                           disabled = {!this.state.manageBalanceBool}
                                           help="If checked, Letus will automatically allow the tenant(s) to pay more than the balance. Payment in excess of balance will become credit." 
                                           handleChange={this.handleChangeOverpayment} onClick = {()=>$("#allow-overpayment-confirm").modal("show")}
                                           parent={this} value={this.state.overpaymentAllowedBool} />
                            
                            <FieldSelect 
                                id="numOfNsfsForBlockingPayment" 
                                label="Block Payment after NSF" model="company"
                                parent={this} 
                                value={this.state.company['numOfNsfsForBlockingPayment']}
                                help="Block Bank Account Payment after NSF has occurred above number of times. Note: Selecting 0 would disable this feature.">
                                <option key={0} value={0}>{0} (disable)</option>
                                {this.MAX_NSF_DAYS.map((data) => {
                                    return (
                                        <option key={data} value={data}>{data}</option>
                                    );
                                })}
                            </FieldSelect>

                            <FieldCheckbox id="chargeNsfFee" label="NSF Fee" fieldLabel="Enable charging of NSF fee"
                                                                       model="company"
                                                                       parent={this} value={this.state.company?.chargeNsfFee} />

                            <FieldText
                                id="nsfFeeAmount"
                                type="number"
                                prependIconName="dollar-sign"
                                prependIconType="fas"
                                label="NSF Fee Amount"
                                model="company"
                                step="any"
                                help="This is the NSF Fee amount that is charged by Letus for NSFs from this company.  Changes to this field will be saved to this company and its subcompanies."
                                parent={this}
                                value={Number(this.state.company?.nsfFeeAmount) || '35'}
                                disabled={!this.state.company?.chargeNsfFee}
                            />

                            <FieldTextarea id="description" label="Description" model="company" parent={this} value={this.state.company['description']}/>

                            <FieldText id="softMaximumPaymentAmount"
                                       label="Soft Threshold Amount"
                                       type="number"
                                       prependIconName="dollar-sign" prependIconType="fas"
                                       min="0" step="1"
                                       model="company"
                                       help="This is the Soft Threshold Amount.  Payments that exceed this amount require a validation be made for this company in Property Manager, Hosted Page and Tenant UI.  Sub Companies created under a parent company will use the Parent Company Soft Threshold Amount value unless updated otherwise."
                                       parent={this}
                                       value={Number(this.state.company?.softMaximumPaymentAmount) || '5000'} />

                            <FieldText id="maximumPaymentAmount"
                                       label="Maximum Threshold Amount"
                                       type="number"
                                       prependIconName="dollar-sign" prependIconType="fas"
                                       min="0" step="1"
                                       model="company"
                                       help="This is the Maximum Threshold Amount.  Payments that exceed this amount cannot be made for this company in Property Manager, Hosted Page and Tenant UI.  Sub Companies created under a parent company will use the Parent Company Maximum Threshold Amount value unless updated otherwise."
                                       parent={this}
                                       value={Number(this.state.company?.maximumPaymentAmount) || '30000'} />

                            <FieldCheckbox id="manageCharges" label="PM Manages Charges" fieldLabel="Allow PM to manage company charges" 
                                           data-toggle="modal" data-target="#manage-charge-confirm" disabled={this.state.tenantUsesCreditReport}
                                           help={"If un-checked, Letus will allow the tenant(s) to create their own payments."} 
                                           warning={this.state.tenantUsesCreditReport ? "A tentant is using the credit reporting service and as such, the PM Manage Balance setting is locked and set to yes." : ""}
                                           handleChange={this.handleChangePmManageCharge} onClick = {()=>$("#manage-charge-confirm").modal("show")}
                                           parent={this} value={this.state.manageBalanceBool} />

                            <FieldCheckbox id="displayChargeCodes" label="Charges for one time payment" fieldLabel="Allow charges for one time payment" model="company"
                                           help="When PM does not Manage charges show dropdown where charges can be selected when tenant makes one time payment."
                                           disabled={this.state.manageBalanceBool}
                                           parent={this} value={this.state.company.displayChargeCodes} />
                            
                            <FieldCheckbox id="enforceCustomAmount" label="Enforce Custom Amount" fieldLabel="Custom Amount required for Autopayment" 
                                           help={"Autopayments that were set without custom amounts prior to enabling this feature will continue to work until a tenant attempts to commit an update. Then the tenant will be presented with a warning that the custom amount must be set. Recurring Billing Report that is available under CSV reports lists tenants that have autopayment setup with and without custom amount."} 
                                           model="company"
                                           disabled={!this.state.manageBalanceBool}
                                           parent={this} value={this.state.company?.enforceCustomAmount} />

                            <FieldCheckbox id="requireAccountId" label="Account Id" fieldLabel="Id is required field during tenant invite"
                                           model="company"
                                           parent={this} value={this.state.company?.requireAccountId} />

                            <FieldCheckbox id="autoFundingReversals" label="Reversal auto-funding" fieldLabel="automatically debit funding account to pay for payment reversals (Returns or NSF, Refunds, Chargebacks)"
                                           model="company"
                                           parent={this} value={this.state.company?.autoFundingReversals} />
                            <FieldSelect
                                id="autoFundingCutoffDays"
                                label="Days" model="company"
                                parent={this}
                                value={this.state.company['autoFundingCutoffDays']}
                                help="Number of days for reversal auto-funding to look back. It never looks back further then the date that the 'reversal auto-funding' checkbox was initially checked.">
                                {this.MAX_AUTO_FUNDING_CUTOFF_DAYS.map((data) => {
                                    return (
                                        <option key={data} value={data}>{data}</option>
                                    );
                                })}
                            </FieldSelect>

                            <FieldCheckbox id="onlyPMPaymentAllowed" label="Only PM Payment" fieldLabel="Only allow PM Payment"
                                           data-toggle="modal" data-target="#manage-charge-confirm"
                                           help="When selected tenants cannot add/delete payment methods and add/update/delete autopayments."
                                           handleChange={this.handleChangeOnlyPMPayment}
                                           parent={this} value={this.state.onlyPMPaymentAllowedBool} />

                            <FieldCheckbox id="terminateExpiredLease" label="Terminate Expired Leases" fieldLabel="Terminate Leases when End Dates is reached"
                                           model="company" parent={this} value={this.state.company?.terminateExpiredLease} />

                            <FieldText id="salesVolume" label="Sales Volume (Yearly) (USD)" pattern="[0-9]+(\.[0-9][0-9]?)?" model="company" type="number" min="1.00" step="0.01" parent={this} value={this.state.company['salesVolume']} required={true}/>
                            
                            <FieldText id="website" label="Website" model="company" parent={this} value={this.state.company['website']} maxLength="150" pattern="https?://.+" required={true} />
                            
                            <FieldText id="productAndServiceDesc" label="Product And Service Description" model="company" parent={this} value={this.state.company['productAndServiceDesc']} maxLength="1500" useTextArea={true} required={true}/>
                        </div>
                    </div>
                    }

                    <div className="card">
                        <div className="card-header">
                            Company Address
                        </div>
                        <div className="card-body">

                            <FieldAddress model="company" parent={this} value={this.state.company} stateProvinceRequired={true} />

                        </div>
                    </div>

                    {(this.state.company && this.state.company.id !== 'LETUS') &&
                    <div className="card">
                        <div className="card-header">
                            Administrators
                        </div>
                        <div className="card-body">

                            <FieldSelect id="accountManagerId" label="Account Manager" model="company" parent={this} value={this.state.company['accountManagerId']}>
                                <option value="">Select an account manager...</option>
                                {this.state.admins &&
                                <React.Fragment>
                                    {this.state.admins.records.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>{data.firstName} {data.lastName}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>

                            <FieldSelect id="salesManagerId" label="Sales Manager" model="company" parent={this} value={this.state.company['salesManagerId']}>
                                <option value="">Select an account manager...</option>
                                {this.state.admins &&
                                <React.Fragment>
                                    {this.state.admins.records.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>{data.firstName} {data.lastName}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>
                            
                            <FieldDate id="masterAgreementDate" label="Master Agreement Date" model="company" parent={this} value={this.state.company['masterAgreementDate']} after={new Date()} required={true}/>
                        </div>
                    </div>
                    }

                    {/*
                    {(this.state.company && this.state.company.id !== 'LETUS') &&
                        <div className="card">
                            <div className="card-header">
                                Notification Provider - Txt2Pay
                            </div>
                        <div className="card-body">

                            <div className="form-group row">
                                <label className={`col-sm-3 col-form-label col-form-label-sm`} htmlFor={this.props.id}>
                                    Providers
                                </label>
                                <div className={`col-sm-9 form-control-sm`}>
                                    <div className="form-check form-check-inline ml-1">
                                        <input className="form-check-input" type="radio" 
                                            name="notificationProviderOptions" 
                                            id="notificationProviderOption1" 
                                            value="LETUS"
                                            onChange={this.handleChangeNotificationProvider}
                                            checked={this.state.company?.notificationProvider === 'LETUS'}
                                        />
                                        <label className="form-check-label" htmlFor="notificationProviderOption1">Letus</label>
                                    </div>
                                    <div className="form-check form-check-inline ml-1">
                                        <input className="form-check-input" 
                                            type="radio" 
                                            name="notificationProviderOptions" 
                                            id="notificationProviderOption2" 
                                            value="EVERYWARE"
                                            onChange={this.handleChangeNotificationProvider}
                                            checked={this.state.company?.notificationProvider === 'EVERYWARE'}
                                        />
                                        <label className="form-check-label" htmlFor="notificationProviderOption2">Everyware</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    */}

                    {this.state.company &&
                    <div className="card">
                        <div className="card-header">
                            Default Merchant Accounts
                        </div>
                        <div className="card-body">
                            <React.Fragment>
                                {!this.state.parentCompany?.id &&
                                    <>
                                        <div className="form-group row">
                                            <label className={`col-sm-3 col-form-label col-form-label-sm`} htmlFor={this.props.id}>
                                                Processor
                                            </label>
                                            <div className={`col-sm-9 form-control-sm`}>
                                                <div className="form-check form-check-inline ml-1">
                                                    <input className="form-check-input" type="radio" 
                                                        name="processorOptions" 
                                                        id="processorOption1" 
                                                        value="PAYSAFE_SPLIT_PAY"
                                                        onChange={this.handleChangeProcessor}
                                                        checked={this.state.paymentProcessor === 'PAYSAFE_SPLIT_PAY'}
                                                    />
                                                    <label className="form-check-label" htmlFor="processorOption1">Paysafe</label>
                                                </div>
                                                <div className="form-check form-check-inline ml-1">
                                                    <input className="form-check-input" 
                                                        type="radio" 
                                                        name="processorOptions" 
                                                        id="processorOption2" 
                                                        value="BLUESNAP"
                                                        onChange={this.handleChangeProcessor}
                                                        checked={this.state.paymentProcessor === 'BLUESNAP'}
                                                    />
                                                    <label className="form-check-label" htmlFor="processorOption2">BlueSnap</label>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.paymentProcessor !== this.state.company.paymentProviderId &&
                                            <FieldCheckbox 
                                                id="changePaymentProcessor" 
                                                model="company"
                                                label="Make Provider Default" 
                                                fieldLabel="Make the selected Processor active"
                                                parent={this} 
                                                value={this.state.company.changePaymentProcessor} 
                                            />
                                        }
                                    </>
                                }
                                <FieldSelect id="VISA" label="Credit/Debit Card" model="creditMerchantAccountList" parent={this} help="Applies to Visa, Visa Electron, Mastercard, Maestro, American Express, Discover, Diners Club, and JCB cards." value={this.state.creditMerchantAccountList?.['VISA']} handleChange={this.handleChangeCreditMerchantAccount}>
                                    <option value="">-</option>
                                    {this.state.visaMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.visaMerchantAccounts.map((data, key) => {
                                            return (
                                                <option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                            );
                                        })}
                                    </React.Fragment>
                                    }
                                </FieldSelect>
                            </React.Fragment>

                            <FieldSelect id="bankMerchantAccountId" label="Bank Account" model="company" parent={this} value={this.state.company['bankMerchantAccountId']}>
                                <option value="">-</option>
                                {this.state.bankMerchantAccounts &&
                                <React.Fragment>
                                    {this.state.bankMerchantAccounts.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>
                            
                            {this.state.company.creditMerchantAccountList &&
                                <FieldSelect id="UNION_PAY" label="UnionPay" parent={this} value={this.state.company.creditMerchantAccountList['UNION_PAY']} handleChange={this.handleChangeUnionPayMerchantAccount}>
                                    <option value="">-</option>
                                    {this.state.unionPayMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.unionPayMerchantAccounts.map((data, key) => {
                                            return (
                                                <option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                            );
                                        })}
                                    </React.Fragment>
                                    }
                                </FieldSelect>
                            }

                            <FieldSelect id="paypalMerchantAccountId" label="PayPal" model="company" parent={this} value={this.state.company['paypalMerchantAccountId']}>
                                <option value="">-</option>
                                {this.state.paypalMerchantAccounts &&
                                <React.Fragment>
                                    {this.state.paypalMerchantAccounts.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>

                            <FieldSelect id="cashMerchantAccountId" label="Cash" model="company" parent={this} value={this.state.company['cashMerchantAccountId']}>
                                <option value="">-</option>
                                {this.state.cashMerchantAccounts &&
                                <React.Fragment>
                                    {this.state.cashMerchantAccounts.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>

                            <FieldSelect id="padMerchantAccountId" label="Pre-Authorized Debit" model="company" parent={this} value={this.state.company['padMerchantAccountId']}>
                                <option value="">-</option>
                                {this.state.padMerchantAccounts &&
                                <React.Fragment>
                                    {this.state.padMerchantAccounts.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>

                            <FieldSelect id="splitItMerchantAccountId" label="SplitIt" model="company" parent={this} value={this.state.company['splitItMerchantAccountId']}>
                                <option value="">-</option>
                                {this.state.bankMerchantAccounts &&
                                <React.Fragment>
                                    {this.state.bankMerchantAccounts.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>

                            <FieldSelect id="applePayMerchantAccountId" label="Apple Pay" model="walletMerchantAccountList" parent={this} value={this.state.walletMerchantAccountList?.['APPLE_PAY']} handleChange={this.handleChangeApplePayMerchantAccount}>
                                <option value="">-</option>
                                {this.state.applePayMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.applePayMerchantAccounts.map((data, key) => {
                                            return (
                                                <option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                            );
                                        })}
                                    </React.Fragment>
                                }
                            </FieldSelect>

                            <FieldSelect id="googlePayMerchantAccountId" label="Google Pay" model="walletMerchantAccountList" parent={this} value={this.state.walletMerchantAccountList?.['GOOGLE_PAY']} handleChange={this.handleChangeGooglePayMerchantAccount}>
                                <option value="">-</option>
                                {this.state.googlePayMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.googlePayMerchantAccounts.map((data, key) => {
                                            return (
                                                <option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                                            );
                                        })}
                                    </React.Fragment>
                                }
                            </FieldSelect>

                        </div>
                        <div className="card-footer">
                            <p className="text-muted small mb-0">
                                If a property under this company does not have any specifically assigned merchant
                                accounts, the property will utilize these default merchant accounts.
                            </p>
                        </div>
                    </div>
                    }

                    <div className="row">

                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

Company.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Company);